import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { motion, useInView } from 'framer-motion';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ApplicationContext } from '../context/AppContext';
import { breakpoints } from '../miscellaneous/breakpoints';
import { item } from '../utils/motionVariants';

export const Contact = () => {
  const toast = useToast();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  // GET THE STATES FROM THE CONTEXT
  const { setActiveNav } = useContext(ApplicationContext);

  // SET THE REF FOR THE ELEMENT TO TRACK
  const ref = useRef(null);
  const isInView = useInView(ref, {
    margin: '-50% 0px -50% 0px',
  });

  // SET THE ACTIVE NAV TO BE THE INDEX 0 WHEN THIS COMPONENT IS VISIBLE ON THE SCREEN
  useEffect(() => {
    if (isInView) {
      setActiveNav(4);
    }
  }, [isInView, setActiveNav]);

  const submit = () => {
    if (!firstName || !lastName || !email || !message) {
      toast({
        title: 'Incomplete field.',
        description: 'Please fill all the fields.',
        status: 'error',
        duration: 2000,
        position: 'top',
        isClosable: true,
      });
      return;
    }
  };

  return (
    <Flex
      id="contact"
      paddingBlock="6rem"
      justifyContent="center"
      alignItems="center"
      ref={ref}
    >
      <Flex
        w={breakpoints}
        margin="auto"
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        as={motion.div}
        variants={item}
        whileInView="show"
        initial="hidden"
        viewport={{ once: true }}
      >
        <HStack align="start" w="100%">
          <Box
            h="2rem"
            w="1rem"
            bg="palette.accent"
            opacity=".5"
            borderRadius=".3rem"
          />
          <VStack w="100%" align="start" spacing="0">
            <Text fontFamily="inter" fontWeight="bold" fontSize="1.5rem">
              Contact
            </Text>
            <Text>Get in touch with us.</Text>
          </VStack>
        </HStack>
        <VStack
          w={{ xl: '75%', base: '100%' }}
          spacing="1.1rem"
          paddingTop="6rem"
        >
          <HStack w="100%" spacing="2rem">
            <Input
              placeholder="First Name"
              w="100%"
              bg="#F2F2F2"
              p="1.5rem"
              onChange={e => setFirstName(e.target.value)}
            />
            <Input
              placeholder="Last Name"
              w="100%"
              bg="#F2F2F2"
              p="1.5rem"
              onChange={e => setLastName(e.target.value)}
            />
          </HStack>
          <Input
            placeholder="Email"
            w="100%"
            bg="#F2F2F2"
            p="1.5rem"
            onChange={e => setEmail(e.target.value)}
          />
          <Textarea
            placeholder="Message"
            w="100%"
            h="20rem"
            minHeight="10rem"
            maxH="30rem"
            bg="#F2F2F2"
            onChange={e => setMessage(e.target.value)}
          />
          <Button
            w="100%"
            bg="palette.accent"
            color="palette.primary"
            fontFamily="inter"
            p="1.6rem"
            transition="all .3s ease"
            _hover={{
              bg: '#3F6CDC',
            }}
            onClick={submit}
          >
            Send Message
          </Button>
        </VStack>
      </Flex>
    </Flex>
  );
};
