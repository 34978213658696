import React from 'react'
import { Footer } from '../components/Footer'
import { About } from '../sections/About'
import { Contact } from '../sections/Contact'
import { Hero } from '../sections/Hero'
import { Portfolio } from '../sections/Portfolio'
import { Resources } from '../sections/Resources'

export const Home = () => {
    return (
        <>
            <Hero/>
            <About/>
            <Portfolio/>
            <Resources />
            <Contact/>
            <Footer/>
        </>
    )
}
