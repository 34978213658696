import {
  Box,
  Flex,
  HStack,
  Image,
  Text,
  VStack,
  Wrap,
  WrapItem,
  Link
} from '@chakra-ui/react';
import { motion, useInView } from 'framer-motion';
import React, { useContext, useEffect, useRef } from 'react';
import { ApplicationContext } from '../context/AppContext';
import { breakpoints } from '../miscellaneous/breakpoints';
import PostgreSQL from '../assets/resources/PostgreSQL.png';
import AmazonWebServices from '../assets/resources/AmazonWebServices.png';
import IOS from '../assets/resources/IOS.png';
import MySQL from '../assets/resources/MySQL.png';
import Python from '../assets/resources/Python.png';
import Linux from '../assets/resources/Linux.png';
import { item } from '../utils/motionVariants';

export const Resources = () => {
  // GET THE STATES FROM THE CONTEXT
  const { setActiveNav } = useContext(ApplicationContext);

  // SET THE REF FOR THE ELEMENT TO TRACK
  const ref = useRef(null);
  const isInView = useInView(ref, {
    margin: '-50% 0px -50% 0px',
  });

  // SET THE ACTIVE NAV TO BE THE INDEX 0 WHEN THIS COMPONENT IS VISIBLE ON THE SCREEN
  useEffect(() => {
    if (isInView) {
      setActiveNav(3);
    }
  }, [isInView, setActiveNav]);

  const resources = [
    {
      name: 'PostgreSQL',
      image: PostgreSQL,
      link: "https://github.com/vaeventures/postgresql/blob/main/postgresql.md"
    },
    {
      name: 'Amazon Web Services',
      image: AmazonWebServices,
      link: "https://github.com/vaeventures/amazonwebservices/blob/main/aws.md"
    },
    {
      name: 'iOS',
      image: IOS,
      link: "https://github.com/vaeventures/ios/blob/main/ios.md"
    },
    {
      name: 'MySQL',
      image: MySQL,
      link: "https://github.com/vaeventures/mysql/blob/main/mysql.md"
    },
    {
      name: 'Linux',
      image: Linux,
      link: "https://github.com/vaeventures/linux/blob/main/linux.md"
    },
    {
      name: 'Python',
      image: Python,
      link: "https://github.com/vaeventures/python/blob/main/python.md"
    }
  ];

  return (
    <Flex
      id="resources"
      paddingBlock="6rem"
      justifyContent="center"
      alignItems="center"
      ref={ref}
      bg="#EFEFEF"
    >
      <Flex
        w={breakpoints}
        margin="auto"
        flexDir="column"
        gap="5rem"
        as={motion.div}
        variants={item}
        whileInView="show"
        initial="hidden"
        viewport={{ once: true }}
      >
        <HStack align="start">
          <Box
            h="2rem"
            w="1rem"
            bg="palette.accent"
            opacity=".5"
            borderRadius=".3rem"
          />
          <VStack align="start" spacing="0">
            <Text fontFamily="inter" fontWeight="bold" fontSize="1.5rem">
              Resources
            </Text>
            <Text>Our Knowledge Center</Text>
          </VStack>
        </HStack>
        <Wrap justify="center" align="center" spacing="5rem">
          {resources.map(resource => {
            return (
              <WrapItem key={resource.name}>
                <Flex w="6rem">
                  <Link href={resource.link} isExternal>
                    <Image src={resource.image} w="100%" />
                  </Link>
                </Flex>
              </WrapItem>
            );
          })}
        </Wrap>
      </Flex>
    </Flex>
  );
};
