import { Box, Divider, HStack, Image, Link, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { breakpoints } from '../miscellaneous/breakpoints';
import Logo from '../assets/Logo.svg';
import { AiOutlineTwitter } from 'react-icons/ai';
import { AiFillLinkedin } from 'react-icons/ai';

export const Footer = () => {
  const navLinks = [
    { link: '#hero', label: 'HOME' },
    { link: '#about', label: 'ABOUT' },
    { link: '#portfolio', label: 'PORTFOLIO' },
    { link: '#resources', label: 'RESOURCES' },
    { link: '#contact', label: 'CONTACT' },
  ];

  return (
    <Box w="100%" bg="#E8E8E8">
      <VStack
        w={breakpoints}
        margin="auto"
        paddingBlock="1.2rem"
        spacing="1.2rem"
      >
        <Image src={Logo} w="5rem" />
        <HStack spacing="1.5rem">
          <Box
            fontSize="1.4rem"
            color="palette.secondary"
            as="a"
            href="https://www.linkedin.com/company/vae-ventures/"
            target="_blank"
          >
            <AiFillLinkedin />
          </Box>
          <Box
            fontSize="1.4rem"
            color="palette.secondary"
            as="a"
            target="_blank"
            href="https://twitter.com/VaeVentures "
          >
            <AiOutlineTwitter />
          </Box>
        </HStack>
        <HStack>
          {navLinks.map((nav, index) => {
            return (
              <Link
                display="flex"
                justifyContent="center"
                alignItems="center"
                key={index}
                href={nav.link}
                transition="all .2s ease"
                fontSize=".84rem"
                pos="relative"
                fontWeight="medium"
                _hover={{
                  color: 'palette.accent',
                }}
              >
                {nav.label}
              </Link>
            );
          })}
        </HStack>
        <Divider borderColor="#D3D3D3" w="50%" />
        <Text fontSize=".8rem">Copyright 2023 | VAE Ventures</Text>
      </VStack>
    </Box>
  );
};
