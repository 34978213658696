
import { extendTheme } from "@chakra-ui/react"

const colors = {
  palette: {
    primary: '#F3F3F3',
    secondary: '#333D54',
    accent: '#5B7DD2',
  },
};

const fonts = {
    body: `'Raleway', sans-serif`,
    heading: `'Inter', sans-serif`,
}

const styles = {
    global: {
        html: {
            scrollBehavior: 'smooth',
            overflowX: 'hidden', 
        },
        body: {
            bg: 'palette.primary',
            color: 'palette.secondary',
        },
        _placeholder: {
            color: 'rgba(38, 50, 56, .6)',
            fontSize: '.9rem'
        },
    }
}

export const theme = extendTheme({ colors, fonts, styles });