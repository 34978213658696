import {
  Flex,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import Logo from '../assets/Logo.svg';

export const MenuModal = ({ isOpen, onClose }) => {
  // INITIALIZE THE ARRAY OF NAV LINKS TO BE MAPPED
  const navLinks = [
    { link: '#hero', label: 'HOME' },
    { link: '#about', label: 'ABOUT' },
    { link: '#portfolio', label: 'PORTFOLIO' },
    { link: '#resources', label: 'RESOURCES' },
    { link: '#contact', label: 'CONTACT' },
  ];
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justifyContent="center" alignItems="center">
            <Image src={Logo} w="5rem" />
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody></ModalBody>

        <ModalFooter>
          <VStack w="100%" paddingBottom="2rem" spacing="1.2rem">
            {navLinks.map((nav, index) => {
              return (
                <Link
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  key={index}
                  href={nav.link}
                  transition="all .2s ease"
                  pos="relative"
                  fontWeight="normal"
                  fontFamily="inter"
                  _hover={{
                    color: 'palette.accent',
                  }}
                  onClick={onClose}
                >
                  {nav.label}
                </Link>
              );
            })}
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
