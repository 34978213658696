import { Flex, Text, VStack, Highlight } from '@chakra-ui/react';
import { motion, useInView } from 'framer-motion';
import React, { useContext, useEffect, useRef } from 'react';
import { ApplicationContext } from '../context/AppContext';
import AboutImage from '../assets/about.png';
import { item } from '../miscellaneous/motionVariants';

export const About = () => {
  // GET THE STATES FROM THE CONTEXT
  const { setActiveNav } = useContext(ApplicationContext);

  // SET THE REF FOR THE ELEMENT TO TRACK
  const ref = useRef(null);
  const isInView = useInView(ref, {
    margin: '-50% 0px -50% 0px',
  });

  // SET THE ACTIVE NAV TO BE THE INDEX 0 WHEN THIS COMPONENT IS VISIBLE ON THE SCREEN
  useEffect(() => {
    if (isInView) {
      setActiveNav(1);
    }
  }, [isInView, setActiveNav]);

  return (
    <Flex id="about" justifyContent="center" alignItems="center" ref={ref}>
      <Flex
        h={{ md: '30rem', base: '20rem' }}
        w="100%"
        bgImage={AboutImage}
        bgPos="center"
        bgSize="cover"
        bgPosition="center"
        p={{ md: '0 8rem', base: '1.5rem' }}
        alignItems="center"
        gap="1.5rem"
        as={motion.div}
        variants={item}
        whileInView="show"
        initial="hidden"
        viewport={{ once: true }}
      >
        <VStack w="100%" align="end" spacing="1.2rem">
          <Text
            color="palette.primary"
            fontWeight="semibold"
            fontSize={{ md: '2rem', base: '1.2rem' }}
            w={{ md: '50%', base: '100%' }}
            fontFamily="inter"
          >
            <Highlight
              query="VAE Ventures"
              styles={{ color: 'palette.accent' }}
            >
              VAE Ventures is a firm dedicated to building extremely high
              quality iOS applications.
            </Highlight>
          </Text>
          <Text
            w={{ md: '50%', base: '100%' }}
            color="palette.primary"
            fontWeight="light"
            opacity=".9"
            fontSize={{ md: '1.1rem', base: '.9rem' }}
          >
            We are very selective about the projects we take on and the
            technologies we support. Our work is backed by years of experience
            focused on designing, building and growing applications around the
            world.
          </Text>
        </VStack>
      </Flex>
    </Flex>
  );
};
