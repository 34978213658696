import {
  Box,
  Button,
  Flex,
  Highlight,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import { motion, useInView } from 'framer-motion';
import React, { useContext, useEffect, useRef } from 'react';
import { ApplicationContext } from '../context/AppContext';
import { breakpoints } from './../miscellaneous/breakpoints';
import HeroImage from '../assets/hero.svg';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { item } from '../miscellaneous/motionVariants';

export const Hero = () => {
  // GET THE STATES FROM THE CONTEXT
  const { setActiveNav } = useContext(ApplicationContext);

  // SET THE REF FOR THE ELEMENT TO TRACK
  const ref = useRef(null);
  const isInView = useInView(ref, {
    margin: '-50% 0px -50% 0px',
  });

  // SET THE ACTIVE NAV TO BE THE INDEX 0 WHEN THIS COMPONENT IS VISIBLE ON THE SCREEN
  useEffect(() => {
    if (isInView) {
      setActiveNav(0);
    }
  }, [isInView, setActiveNav]);

  return (
    <Flex
      id="hero"
      h={{ base: '100%', md: "100vh" }}
      paddingBlock={{base: '10rem', md: '0'}}
      justifyContent="center"
      alignItems="center"
      ref={ref}
    >
      <Flex
        w={breakpoints}
        margin="auto"
        justifyContent="center"
        alignItems="center"
        flexDir={{ base: 'column', md: 'row' }}
        gap={{base: '4rem', md: '0'}}
        as={motion.div}
        variants={item}
        whileInView="show"
        initial="hidden"
        viewport={{ once: true }}
      >
        <VStack flex={1.5} w="100%" align="start">
          <Text
            color="palette.secondary"
            fontWeight="black"
            fontSize={{base: '2rem', md:"5rem"}}
            fontFamily="inter"
          >
            <Highlight query="iOS Reality" styles={{ color: 'palette.accent' }}>
              Transforming Ideas Into iOS Reality.
            </Highlight>
          </Text>
          <Text fontSize="1.1rem">
            We specialize in taking innovative concepts and turning them into
            high-performing, visually stunning iOS applications that exceed user
            expectations and drive business success.
          </Text>
          <Box paddingTop="2rem">
            <Button
              bg="palette.accent"
              color="palette.primary"
              p="1.8rem"
              fontSize="1.1rem"
              rightIcon={<AiOutlineArrowRight />}
              fontFamily="inter"
              transition="all .3s ease"
              _hover={{
                bg: '#3F6CDC',
              }}
              as='a'
              href='#portfolio'
            >
              Our Portfolio
            </Button>
          </Box>
        </VStack>
        <Flex flex={1} justifyContent='center' alignItems='center' w={{base: '75%', md: '100%'}}>
          <Image src={HeroImage} w=''/>
        </Flex>
      </Flex>
    </Flex>
  );
};
