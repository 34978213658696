import { Box, Button, Flex, HStack, Text, VStack, Link } from '@chakra-ui/react';
import { motion, useInView } from 'framer-motion';
import React, { useContext, useEffect, useRef } from 'react';
import { ApplicationContext } from '../context/AppContext';
import { breakpoints } from './../miscellaneous/breakpoints';

import AroundImage from '../assets/around.png';
import SpotDropImage from '../assets/spotDrop.jpg';
import BucketImage from '../assets/bucket.png';
import RevBoutique from '../assets/revboutique.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import { FreeMode, Pagination } from 'swiper';
import { item } from '../miscellaneous/motionVariants';

export const Portfolio = () => {
  // GET THE STATES FROM THE CONTEXT
  const { setActiveNav } = useContext(ApplicationContext);

  // SET THE REF FOR THE ELEMENT TO TRACK
  const ref = useRef(null);
  const isInView = useInView(ref, {
    margin: '-50% 0px -50% 0px',
  });

  // SET THE ACTIVE NAV TO BE THE INDEX 0 WHEN THIS COMPONENT IS VISIBLE ON THE SCREEN
  useEffect(() => {
    if (isInView) {
      setActiveNav(2);
    }
  }, [isInView, setActiveNav]);

  const projects = [
    {
      title: 'Around',
      description: 'Be spontaneous',
      link: 'https://aroundapp.live/',
      image: AroundImage
    },
    {
      title: 'SpotDrop',
      description: 'For foodies, by foodies',
      link: 'https://www.spotdropapp.com/',
      image: SpotDropImage
    },
    {
      title: 'Bucket',
      description: 'Bucket lists made social',
      link: 'https://apps.apple.com/us/app/bucket-a-bucket-list-app/id1509377504',
      image: BucketImage
    },
    {
      title: 'Revolution Boutique Havana',
      description: 'Where luxury meets history',
      link: 'https://revboutique.com/',
      image: RevBoutique
    },
  ];

  return (
    <Flex
      id="portfolio"
      paddingBlock="6rem"
      justifyContent="center"
      alignItems="center"
      ref={ref}
    >
      <Flex
        w={breakpoints}
        margin="auto"
        flexDir="column"
        gap="8rem"
        as={motion.div}
        variants={item}
        whileInView="show"
        initial="hidden"
        viewport={{ once: true }}
      >
        <HStack align="start">
          <Box
            h="2rem"
            w="1rem"
            bg="palette.accent"
            opacity=".5"
            borderRadius=".3rem"
          />
          <VStack align="start" spacing="0">
            <Text fontFamily="inter" fontWeight="bold" fontSize="1.5rem">
              Portfolio
            </Text>
            <Text>Our Current Projects</Text>
          </VStack>
        </HStack>
        <Box w={{ base: '100%', xl: '85%' }} margin="auto">
          <Swiper
            freeMode
            modules={[FreeMode, Pagination]}
            style={{
              width: '100%',
              height: '100%',
              background: 'rgba(0, 0, 0, .01)',

              borderRadius: '.8rem',
            }}
            spaceBetween={10}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              780: {
                slidesPerView: 2,
              },
              1320: {
                slidesPerView: 3,
              },
            }}
            pagination={{
              clickable: true,
            }}
            loop
          >
            {projects.map(project => {
              return (
                <SwiperSlide key={project.title} p="1rem">
                  <Flex

                    h="30rem"

                    borderRadius="1rem"
                    backgroundImage={`url(${project.image})`}
                    bgPos="center"
                    bgSize="cover"
                    bgRepeat="no-repeat"
                    align="end"
                    boxShadow="2px 2px 8px rgba(0, 0, 0, .5)"
                  >
                    <VStack
                      h="40%"
                      w="100%"
                      bg="rgba(0, 0, 0, .7)"
                      borderBottomRadius="1rem"
                      p="1.5rem"
                      align="start"
                    >
                      <Text
                        color="palette.primary"
                        fontWeight="semibold"
                        fontFamily="inter"
                        fontSize="1.3rem"
                      >
                        {project.title}
                      </Text>
                      <Text
                        color="palette.primary"
                        opacity=".9"
                        fontWeight="light"
                        fontSize=".9rem"
                      >
                        {project.description}
                      </Text>
                      <Box paddingTop="1rem">
                        <Link href={project.link} isExternal>
                          <Button
                            bg="palette.accent"
                            color="palette.primary"
                            transition="all .3s ease"
                            fontFamily="inter"
                            
                            _hover={{
                              bg: '#3F6CDC',
                            }}
                          >
                            Check Out
                          </Button>
                        </Link>
                      </Box>
                    </VStack>
                  </Flex>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      </Flex>
    </Flex>
  );
};
