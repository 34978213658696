import { Box, Flex, Image, Link, useMediaQuery } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { ApplicationContext } from '../context/AppContext';

import Logo from '../assets/Logo.svg';
import { MobileMenu } from './MobileMenu';
import { motion } from 'framer-motion';
import { container, item } from '../miscellaneous/motionVariants';
import { breakpoints } from '../miscellaneous/breakpoints';

export const Navbar = () => {
  // GET THE STATES FROM THE CONTEXT
  const { scrolled, activeNav } = useContext(ApplicationContext);

  // TRACK SCREEN SIZE TO ADJUST THE NAV APPEARANCE
  const [isSmallerThan850] = useMediaQuery('(max-width: 850px)');

  // INITIALIZE THE ARRAY OF NAV LINKS TO BE MAPPED
  const navLinks = [
    { link: '#hero', label: 'HOME' },
    { link: '#about', label: 'ABOUT' },
    { link: '#portfolio', label: 'PORTFOLIO' },
    { link: '#resources', label: 'RESOURCES' },
    { link: '#contact', label: 'CONTACT' },
  ];

  const activeNavStyle = {
    color: 'palette.accent',
  };

  return (
    <Box
      w="100%"
      transition="all .2s ease"
      h={scrolled ? '5.9rem' : '6rem'}
      pos="fixed"
      zIndex="100"
      bg={scrolled ? 'palette.primary' : 'transparent'}
      boxShadow={scrolled && '0 0 10px rgba(0, 0, 0, .2)'}
    >
      <Flex
        w={breakpoints}
        h="100%"
        margin="auto"
        alignItems="center"
        justifyContent="space-between"
        as={motion.div}
        variants={container}
        animate="show"
        initial="hidden"
      >
        {/* LOGO */}
        <Link href="#hero">
          <Image
            transition="all .3s ease"
            w={scrolled ? '4.9rem' : '5rem'}
            src={Logo}
            as={motion.img}
            variants={item}
          />
        </Link>

        {/* NAVIGATION LINKS */}
        {isSmallerThan850 ? (
          <MobileMenu />
        ) : (
          <>
            <Flex alignItems="center" justifyContent="center" gap="2rem">
              {navLinks.map((nav, index) => {
                return (
                  <Link
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    key={index}
                    href={nav.link}
                    transition="all .2s ease"
                    fontSize={scrolled ? '.83rem' : '.84rem'}
                    pos="relative"
                    fontWeight="medium"
                    {...(activeNav === index && activeNavStyle)}
                    _hover={{
                      color: 'palette.accent',
                    }}
                    as={motion.a}
                    variants={item}
                  >
                    {nav.label}
                    {activeNav === index && (
                      <Box
                        pos="absolute"
                        bottom="-.6rem"
                        w=".5rem"
                        h=".5rem"
                        borderRadius="50%"
                        bg="palette.accent"
                        as={motion.div}
                        layoutId
                      />
                    )}
                  </Link>
                );
              })}
            </Flex>
          </>
        )}
      </Flex>
    </Box>
  );
};
